
.right-sidebar {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 5%;
  top: 10px;
}

.sidebar-icon {
  margin: 10px 0;
}

.sidebar-icon img {
  width: 32px;
  height: 32px;
}