.sidebar {
  padding: 20px;
}

.sidebar-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
}

.sidebar-button.generate {
  background-color: #FF6F61;
  color: white;
}

.sidebar-button.save {
  background-color: #4CAF50;
  color: white;
}

.sidebar-button.increase {
  background-color: #4CAF50;
  color: white;
}

.total-characters {
  text-align: left;
  font-size: 1.2em;
  margin: 10px 0;
}

.article-list {
  margin-top: 20px;
}

.article-list h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.article-list ul {
  list-style: none;
  padding: 0;
  filter: blur(4px);
}

.article-list li {
  margin-bottom: 10px;
}

.article-list img {
  width: 100%;
  height: auto;
  display: block;
}

.article-list h4 {
  font-size: 1em;
  margin: 5px 0;
}

.article-list p {
  font-size: 0.9em;
  margin: 0;
}
