.login-form {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-form input {
  width: 93%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form .links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-form a {
  color: #28a745;
  text-decoration: none;
  font-size: 14px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #218838;
}
