/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 100vh;
  margin-bottom: 20px;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
  pointer-events: auto;
}

.sidebar {
  background-color: white;
  width: 300px;
  height: auto;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.login-icon {
  display: flex;
  justify-content: flex-start;
}

.sidebar-item {
  margin-bottom: 18px;
}

.thumbnail img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.article-list.blur {
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.generate-article {
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none!important;
}

.content {
  background-color: white;
  width: 50vw;
  overflow: visible scroll;
  margin-left: 20px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

input[type="text"] {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}

html {
  width: 100%;
}

button.signup-close {
  margin-top: 20px;
}

.modal-content {
  width: 300px;
}