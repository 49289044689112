.generate-article {
  -ms-filter: blur(0px);
  filter: blur(0px);
}

.article-list {
  opacity: 1;
  -ms-filter: blur(0px);
  filter: blur(0px);
}

.login-required {
  opacity: 1;
  -ms-filter: blur(0px);
  filter: blur(0px);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
}

.container {
  display: flex;
}

.content {
  flex: 1;
  padding: 20px;
}

.article-container {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.article-header {
  text-align: center;
}

.article-thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.article-body {
  margin-top: 20px;
}

.toc {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  background-color: rgba(243, 244, 246);
}

.toc, .content-section {
  margin-bottom: 20px;
}
.toc h2, .content-section h2 {
  filter: blur(4px);
}


.toc h3, .content-section h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.generate-article {
  font-size: 1em;
  line-height: 1.6;
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.generate-article.blur-none {
  -ms-filter: blur(0px);
  filter: blur(0px);
}

.table-of-contents a {
  color: black;
  text-decoration: none;
  padding-bottom: 6px;
} 